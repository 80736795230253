<template>
  <div v-if="filterForm">
    <div class="field">
      <label for="filterBy">Filtrar por</label>
      <select
        id="filterBy"
        class="tui tuim ui search dropdown"
        placeholder="Escolha o tipo de dado"
        v-model="filterBy"
        @change="clearQueryField"
      >
        <option value>Escolha o tipo de dado</option>
        <option
          v-for="(item, index) in filters"
          :key="index"
          :value="item.key"
          v-text="item.text"
        />
      </select>
    </div>

    <div class="field" v-show="filterBy === 'status'">
      <select
        name="filterDonor[status]"
        class="tui tuim ui search dropdown"
        placeholder="Escolha o tipo de dado"
        v-model="donorStatus"
      >
        <option value>Status</option>
        <option
          v-for="(item, index) in status"
          :key="index"
          :value="item.key"
          v-text="item.text"
        />
      </select>
    </div>

    <div class="field" v-show="filterBy === 'name'">
      <input type="text" name="filterDonor[name]" placeholder="Nome" v-model="filterForm.fieldOne">
    </div>

    <div class="field" v-show="filterBy === 'activeDonations'">
      <div class="field">
        <select
          name="filterDonor[operatorqty]"
          class="tui tuim ui search dropdown"
          placeholder="Escolha o tipo de dado"
          v-model="operatorQty"
        >
          <option value>Status</option>
          <option
            v-for="(item, index) in operators"
            :key="index"
            :value="item.key"
            v-text="item.text"
          />
        </select>
      </div>
      <div class="field">
        <input :disabled="!operatorQty" type="number" name="filterDonor[qty]" class="donation-qty" placeholder="Quantidade" v-model="filterForm.fieldOne">
      </div>
    </div>

    <div v-show="filterBy === 'activeSince'">
      <div class="field" :class="{ 'error' : errorDate(filterForm.fieldOne) }">
        <the-mask
          v-if="filterBy === 'activeSince'"
          :mask="['##/##/####']"
          v-model="filterForm.fieldOne"
          :masked="true"
          placeholder="Período Inicial" />
      </div>
      <div class="field" :class="{ 'error' : errorDate(filterForm.fieldTwo) }">
        <the-mask
          v-if="filterBy === 'activeSince'"
          :mask="['##/##/####']"
          v-model="filterForm.fieldTwo"
          :masked="true"
          placeholder="Período Final" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { checkDate } from '@/helpers'

export default {
  name: 'TDonorsFilters',
  data () {
    return {
      filters: [
        { key: 'status', text: 'Status do doador' },
        { key: 'name', text: 'Nome do doador' },
        { key: 'activeDonations', text: 'Doações ativas' },
        { key: 'activeSince', text: 'Ativo desde' }
      ],
      status: [
        { key: 'inactive', text: 'Inativo' },
        { key: 'active', text: 'Ativo' }
      ],
      filterBy: null,
      operatorQty: null,
      donorStatus: null,
      operators: [
        { key: '=', text: 'Igual a' },
        { key: '>', text: 'Superior a' },
        { key: '<', text: 'Inferior a' },
      ]
    }
  },

  computed: {
    ...mapState({
      filterForm: ({ donors }) => donors.filterForm
    })
  },

  watch: {
    operatorQty (value) {
      this.filterForm.equalTo = this.operators.find( operator => operator.key === value)
    },

    filterBy (value) {
      this.filterForm.filterBy = this.filters.find( filter => filter.key === value )
    },

    donorStatus (value) {
      this.filterForm.fieldOne = this.status.find( status => status.key === value )
    }
  },

  methods: {
    bindPlugins () {
      $('.ui.search.dropdown').dropdown()
    },

    errorDate (value) {
      if (this.filterBy && this.filterBy === 'activeSince') {
        return checkDate(value)
      }
      return false
    },

    clearQueryField () {
      if (this.filterForm.fieldOne) {
        this.filterForm.fieldOne = ''
      }
      if (this.filterForm.fieldTwo) {
        this.filterForm.fieldTwo = ''
      }
    }
  },

  mounted () {
    this.bindPlugins()
  }

}
</script>

<style lang="scss" scoped>
  .donation-qty {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }
</style>
